import React, { useState, useEffect, useCallback } from 'react';
import { AlertCircle, Shield, Activity, ExternalLink, MessageSquare, Cloud } from 'lucide-react';

// Custom Card component
const Card = ({ children, className }) => (
  <div className={`bg-gray-900 border border-gray-800 rounded-lg p-6 text-white flex flex-col items-center ${className}`}>
    {children}
  </div>
);

// Custom Alert component
const Alert = ({ children, className }) => (
  <div className={`bg-blue-900 border-l-4 border-blue-500 text-white p-4 ${className}`} role="alert">
    {children}
  </div>
);

const FeatureCard = ({ icon: Icon, title, description }) => (
  <Card>
    <div className="w-12 h-12 rounded-full bg-indigo-600 flex items-center justify-center mb-4">
      <Icon className="h-6 w-6 text-white" />
    </div>
    <h3 className="text-lg font-medium text-white mb-2 text-center">{title}</h3>
    <p className="text-gray-300 text-center">{description}</p>
  </Card>
);

const ImageCard = ({ src, alt }) => (
  <div className="relative w-[200px] h-[200px] bg-gray-900 rounded-lg overflow-hidden border border-gray-800 mx-auto">
    <img
      src={src}
      alt={alt}
      className="w-full h-full object-cover"
    />
    <div className="absolute inset-0 bg-black bg-opacity-60 flex items-center justify-center">
      <p className="text-white text-sm font-semibold text-center px-2">{alt}</p>
    </div>
  </div>
);

const securityTips = [
  "Always use the latest version of your container images.",
  "Implement least privilege access control for your containers.",
  "Regularly scan your containers for vulnerabilities.",
  "Use network segmentation to isolate container workloads.",
  "Enable logging and monitoring for all container activities.",
  "Implement a robust secrets management solution for your containers.",
  "Use read-only file systems where possible to prevent modifications.",
  "Limit and audit all API access to your container orchestration platform.",
  "Implement runtime security controls to detect and prevent anomalies.",
  "Regularly update and patch your container host operating systems."
];

function App() {
  const [securityTip, setSecurityTip] = useState('');

  const getRandomTip = useCallback(() => {
    const randomIndex = Math.floor(Math.random() * securityTips.length);
    return securityTips[randomIndex];
  }, []);

  useEffect(() => {
    setSecurityTip(getRandomTip());
  }, [getRandomTip]);

  useEffect(() => {
    // Set the document title
    document.title = "FCG Shield - ContainerGuard";
  }, []); // Empty dependency array ensures this runs only once on component mount

  return (
    <div className="min-h-screen bg-black text-white py-12 px-4 sm:px-6 lg:px-8">
      <div className="max-w-7xl mx-auto">
        <div className="text-center">
          <h1 className="text-4xl font-extrabold sm:text-5xl sm:tracking-tight lg:text-6xl text-white">
            FCG Shield - ContainerGuard
          </h1>
          <p className="mt-5 max-w-xl mx-auto text-xl text-gray-300 text-center">
            Securing your containers with advanced threat detection and real-time monitoring.
          </p>
          <div className="mt-8 flex flex-col sm:flex-row justify-center space-y-4 sm:space-y-0 sm:space-x-4">
            <a
              href="https://aws.amazon.com/marketplace/seller-profile?id=391b449b-1127-49da-bb1c-b704841be602&ref=dtl_prodview-zw3a7sfpiqg4m"
              target="_blank"
              rel="noopener noreferrer"
              className="inline-flex items-center px-6 py-3 border border-transparent text-base font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 transition duration-150 ease-in-out"
            >
              View on AWS Marketplace
              <ExternalLink className="ml-2 h-5 w-5" />
            </a>
            <a
              href="https://join.slack.com/t/autocloudtek/shared_invite/zt-2rfozo7mt-6ERJklV9Jz4GFVMUjxlU5Q"
              target="_blank"
              rel="noopener noreferrer"
              className="inline-flex items-center px-6 py-3 border border-transparent text-base font-medium rounded-md text-white bg-green-600 hover:bg-green-700 transition duration-150 ease-in-out"
            >
              Join Our Slack for Support
              <MessageSquare className="ml-2 h-5 w-5" />
            </a>
          </div>
        </div>
        <div className="mt-10">
          <div className="grid grid-cols-1 gap-8 sm:grid-cols-2 lg:grid-cols-3">
            <FeatureCard
              icon={AlertCircle}
              title="Threat Detection"
              description="Advanced algorithms to detect and prevent security threats in real-time, integrated with AWS EKS, and Cloudwatch"
            />
            <FeatureCard
              icon={Shield}
              title="Vulnerability Scanning"
              description="Continuous scanning for known vulnerabilities in your container images."
            />
            <FeatureCard
              icon={Activity}
              title="Real-time Monitoring"
              description="24/7 monitoring of your container environment for suspicious activities."
            />
            <FeatureCard
              icon={Cloud}
              title="AWS Security Consulting"
              description="Expert guidance on AWS security best practices, SSO, compliance, and architecture design."
            />            
            <FeatureCard
              icon={Cloud}
              title="AWS EKS Consulting"
              description="Expert guidance on AWS EKS, Istio, Service Mesh, Deployments, CICD"
            />               
            <FeatureCard
              icon={Cloud}
              title="AWS BedRock and Sagemaker Consulting"
              description="Expert guidance on AWS Bedrock, Sagemaker and AI Solutions"
            />               
          </div>
        </div>
        <div className="mt-10">
          <Alert>
            <div className="flex items-center justify-center">
              <AlertCircle className="h-4 w-4 mr-2" />
              <strong className="text-lg">Security Tip of the Day</strong>
            </div>
            <p className="mt-2 text-center">{securityTip}</p>
          </Alert>
        </div>
        <div className="mt-16">
          <h2 className="text-2xl font-bold text-center mb-8">Our Comprehensive AWS Security Services</h2>
          <div className="grid grid-cols-1 gap-8 sm:grid-cols-2 lg:grid-cols-3">
            <Card>
              <h3 className="text-xl font-semibold mb-4">Container Security</h3>
              <p>Secure your containerized applications with our advanced ContainerGuard solution.</p>
            </Card>
            <Card>
              <h3 className="text-xl font-semibold mb-4">AWS Infrastructure Security</h3>
              <p>Ensure your AWS infrastructure is configured securely, follows best practices, and meets PCI, SOC2 requirements</p>
            </Card>
            <Card>
              <h3 className="text-xl font-semibold mb-4">AWS Security Consulting</h3>
              <p>Get expert guidance on AWS security, SSO, compliance, and architectural best practices.</p>
            </Card>
          </div>

        </div>
      </div>
    </div>
  );
}

export default App;